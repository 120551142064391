/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'

import Store from '@/store'

import authRoutes from './auth'
import accountRoutes from './account'
import Auth from '@/views/Auth.vue'

Vue.use(VueRouter)

const routes = [
  ...accountRoutes,
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/Faq.vue'),
    meta: {
      page: 4
    }
  },
  {
    path: '/help-login',
    name: 'help-login',
    component: () => import('@/views/FaqLogin.vue'),
    meta: {
      page: 4
    }
  },
  {
    path: '/inspiration',
    name: 'inspiration',
    component: () => import('@/views/Inspiration.vue'),
    meta: {
      page: 4
    }
  },
  {
    path: '/actie',
    name: 'tellafriend',
    component: () => import('@/views/TellAFriend/TellAFriend.vue'),
    meta: {
      page: 4
    }
  },
  {
    path: '/cashback-actie',
    name: 'cashback-action',
    component: () => import('@/views/CashBackAction.vue'),
    meta: {
      page: 4
    }
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: () => import('@/views/Campaign/Campaign.vue'),
    meta: {
      page: 4
    }
  },
  {
    path: '/users/details',
    name: 'dashboard',
    component: () => import('@/views/Users/Dashboard.vue'),
    meta: {
      page: 3
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users/Index.vue'),
    meta: {
      page: 2
    }
  },
  {
    path: '/users/details/info',
    name: 'detail',
    component: () => import('@/views/Users/Detail.vue'),
    meta: {
      page: 4
    }
  },
  {
    path: '/users/details/info/edit',
    name: 'detailEdit',
    component: () => import('@/views/Users/DetailEdit.vue'),
    meta: {
      page: 5
    }
  },

  {
    path: '/users/employer',
    name: 'employer',
    component: () => import('@/views/Employer/Employer.vue'),
    meta: {
      page: 4
    }
  },

  {
    path: '/users/subscription',
    name: 'subscription',
    component: () => import('@/views/Subscription/Subscription.vue'),
    meta: {
      page: 4
    }
  },
  {
    path: '/users/subscription/actions',
    name: 'subscriptionActions',
    component: () => import('@/views/Subscription/Actions.vue'),
    meta: {
      page: 5
    }
  },
  {
    path: '/users/subscription/cancel',
    name: 'subscriptionCancel',
    component: () => import('@/views/Subscription/Cancel.vue'),
    meta: {
      page: 6
    }
  },
  {
    path: '/users/subscription/cancel-confirm',
    name: 'subscriptionCancelConfirm',
    component: () => import('@/views/Subscription/Cancelconfirmation.vue'),
    meta: {
      page: 6
    }
  },
  {
    path: '/users/subscription/cancel-deleted',
    name: 'subscriptionCancelDeleted',
    component: () => import('@/views/Subscription/CancelDeleted.vue'),
    meta: {
      page: 6
    }
  },
  {
    path: '/users/subscription/suspend',
    name: 'subscriptionSuspend',
    component: () => import('@/views/Subscription/Suspend.vue'),
    meta: {
      page: 6
    }
  },
  {
    path: '/users/subscription/updateSubscriptionSuspension',
    name: 'updateSubscriptionSuspension',
    component: () => import('@/views/Subscription/UpdateSubscriptionSuspension.vue'),
    meta: {
      page: 6
    }
  },
  {
    path: '/users/subscription/edit',
    name: 'subscriptionEdit',
    component: () => import('@/views/Subscription/Edit.vue'),
    meta: {
      page: 6
    }
  },
  // {
  //   path: '/users/subscription/lockdown',
  //   name: 'subscriptionLockdownSuspend',
  //   component: () => import('@/views/Subscription/Lockdown.vue'),
  //   meta: {
  //     page: 6
  //   }
  // },
  // {
  //   path: '/users/subscription/lockdown-suspend-confirm',
  //   name: 'lockdownSuspendConfirm',
  //   component: () => import('@/views/Subscription/LockdownSuspendConfirm.vue'),
  //   meta: {
  //     page: 6
  //   }
  // },
  {
    path: '/',
    component: Auth,
    children: authRoutes,
    beforeEnter: (to: Route, from: Route, next: Function) => {
      const isAuthenticated = Store.getters['Auth/isAuthenticated']
      if (isAuthenticated) {
        // If requested page is /auth/reset-password, then redirect to /account/change-password
        if (to.name === 'resetPassword') {
          next({ path: '/account/change-password' })
        } else if (to.name === 'activateAccount' || to.name === 'loginWithToken') {
          next()
        } else if (to.name === 'logout') {
          next()
        } else if (to.name === 'underconstruction') {
          next()
        } else {
          // Else redirect to user overview
          next({ path: '/users' })
        }
      } else {
        next()
      }
    }
  }
]

const router: VueRouter = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login' || to.name === 'help-login' || to.path?.indexOf('auth') >= 0) {
    next()
    return
  }

  const isAuthenticated = Store.getters['Auth/isAuthenticated']
  if (!isAuthenticated) {
    next({
      path: '/'
    })
  } else {
    next()
  }
})

export default router
